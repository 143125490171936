import { render, staticRenderFns } from "./type.vue?vue&type=template&id=1e8fb932&scoped=true&"
import script from "./type.vue?vue&type=script&lang=js&"
export * from "./type.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e8fb932",
  null
  
)

export default component.exports