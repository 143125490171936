/* 
 * @Author: 曹俊杰 
 * @Date: 2023-02-17 01:42:46
 * @Module: 个人中心
 */
 <template>
  <div class="personal border">
    <div class="item">
      <div class="label">姓名：</div>
      <div class="value">
        <p>{{user.name||"暂无信息"}}</p>
      </div>
    </div>
    <div class="item">
      <div class="label">手机号：</div>
      <div class="value">
        <p>{{user.phone||"暂无信息"}}</p>
      </div>
    </div>
    <div class="item">
      <div class="label">身份证号：</div>
      <div class="value">
        <p>{{user.idNumber||"暂无信息"}}</p>
      </div>
    </div>
    <div class="item">
      <div class="label">现住址：</div>
      <div class="value">
        <p>{{user.address||"暂无信息"}}</p>
      </div>
    </div>
    <div class="item">
      <div class="label">籍贯：</div>
      <div class="value">
        <p>{{user.nativePlace||"暂无信息"}}</p>
      </div>
    </div>
    <div class="line2"></div>
    <div class="title">紧急联系人</div>
    <div class="item">
      <div class="label">姓名：</div>
      <div class="value">
        <p>{{user.contactName||"暂无信息"}}</p>
      </div>
    </div>
    <div class="item">
      <div class="label">电话：</div>
      <div class="value">
        <p>{{user.contactPhone||"暂无信息"}}</p>
      </div>
    </div>
    <div class="item">
      <div class="label">关系：</div>
      <div class="value">
        <p>{{user.contactRelation||"暂无信息"}}</p>
      </div>
    </div>
    <div class="line2"></div>
    <div class="title">账号信息</div>
    <div class="item">
      <div class="label">当前等级：</div>
      <div class="value">
        <p>{{user.level|level}}</p>
      </div>
    </div>
    <div class="item">
      <div class="label">业务分润比例：</div>
      <div class="value">
        <span v-if="user.proportion">{{user.proportion}}%</span>
        <span v-else>暂无信息</span>
      </div>
    </div>
    <div class="item">
      <div class="label">介绍人：</div>
      <div class="value">
        <p>{{user.introducer?user.introducer.name?user.introducer.name:"暂无信息":"暂无信息"}}</p>
      </div>
    </div>
    <div class="item">
      <div class="label">创建时间：</div>
      <div class="value">
        <span v-if="user.createdAt">{{ user.createdAt|timeFormat }}</span>
        <span v-else>暂无信息</span>
      </div>
    </div>
  </div>
</template>
 <script>
import { mapState } from "vuex";
export default {
  components: {},
  data () {
    return {};
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
	 filters: {
    level (num) {
      if (num == 1) {
        return 'S'
      } else if (num == 2) {
        return 'A'
      } else if (num == 3) {
        return 'B'
      } else if (num == 4) {
        return 'C'
      } else if (num == 5) {
        return 'L'
      } else {
        return '暂无数据'
      }
    }
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.personal {
  padding-bottom: 30px;
  .line2 {
    background: linear-gradient(
      to left,
      transparent 0%,
      transparent 50%,
      #eee 50%,
      #eee 100%
    );
    background-size: 10px 1px;
    background-repeat: repeat-x;
    height: 1px;
    margin: 0 20px;
  }
  .title {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 56px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: #262626;
  }
  .item {
    display: flex;
    padding: 0 20px;
    line-height: 44px;
    align-items: center;
    position: relative;
    .label {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }
    .value {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }
  }
}
</style>