import request from '@/utils/request'

const api = {

	//登录
	postLogin (params) {
		return request.post(`/api/account/login`, params)
	},
	//获取登录验证码
	postSmsCodeLogin (params) {
		return request.post(`/api/sms/code/login`, params)
	},
	// 获取课程列表
	getCourseList (params) {
		return request.get(`/api/course`, params)
	},
	// 获取课程详情
	getCourseDetail (params) {
		return request.get(`/api/course/${params.uuid}`)
	},
	// 获取题目列表
	getQuestion (params) {
		return request.get(`/api/question`, params)
	},
	// 回答题目
	postQuestionAnswer (params) {
		return request.post(`/api/question/answer`, params)
	},


	//成单下拉菜单
	getDealCandidateList (params) {
		return request.get(`/api/deal/candidate`, params)
	},
	//会员列表下拉菜单
	getAccountCandidateList (params) {
		return request.get(`/api/account/candidate`, params)
	},
	//分佣列表
	getCommissionList (params) {
		return request.get(`/api/commission`, params)
	},
	//修改分佣
	putCommission (params) {
		return request.put(`/api/commission`, params)
	},
	//创建分佣
	postCommission (params) {
		return request.post(`/api/commission`, params)
	},
	//删除分佣
	deleteCommission (params) {
		return request.delete(`/api/commission/${params.uuid}`)
	},
	//分佣统计
	getCommissionProfile (params) {
		return request.get(`/api/commission/profile`, params)
	},

	//创建分佣提现申请
	postWithdrawApp (params) {
		return request.post(`/api/withdraw-app`, params)
	},
	//分佣提现申请列表
	getWithdrawAppList (params) {
		return request.get(`/api/withdraw-app`, params)
	},
	//撤消分佣提现申请
	putWithdrawAppCancel (params) {
		return request.put(` /api/withdraw-app/cancel`, params)
	},
	//审批分佣提现申请
	putWithdrawApp (params) {
		return request.put(`/api/withdraw-app/process`, params)
	},

	//账号会员树列表
	getAccountTreeList (params) {
		return request.get(`/api/account/tree`, params)
	},

	//获取账号列表
	getAccountList (params) {
		return request.get(`/api/account`, params)
	},
	//添加帐号
	putAddAccount (params) {
		return request.put(`/api/account`, params)
	},
	//修改账号
	putRevisionAccount (params) {
		return request.put(`/api/account/${params.uuid}`, params)
	},


	//成单记录列表
	getDealList (params) {
		return request.get(`/api/deal`, params)
	},
	//修改成单记录
	putDeal (params) {
		return request.put(`/api/deal`, params)
	},
	//创建成单记录
	postDeal (params) {
		return request.post(`/api/deal`, params)
	},


}

export default api