import Router from '@/router'
const state = {
	courseDetail: {
		uuid: '',
		name: '',
		coverUrl: '',
		totalLesson: 0,
		passedLesson: 0,
		lessons: [],
		createdAt: 0,
	}
}

const mutations = {
	setCourseDetail (state, payload) {
		state.courseDetail = payload;
		// state.commit("userLog/saveUserLog", state.user)
	},
	clear (state) {
		state.courseDetail = {
			uuid: '',
			name: '',
			coverUrl: '',
			totalLesson: 0,
			passedLesson: 0,
			lessons: [],
			createdAt: 0,
		}
	}

}
const actions = {
	setCourseDetail ({ commit, state, dispatch }, params) {
		commit('setCourseDetail', params);
	},
	setPassedLesson ({ commit, state, dispatch }, params) {
		commit('setCourseDetail', { ...state.courseDetail, passedLesson: params });
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations
}