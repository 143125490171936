/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-03 16:02:31
 * @Module: 菜单
 */
 <template>
  <div class="menu" :class="{collapse:isCollapse}">
    <div class="top">
      <div class="logo"></div>
      <i :class="isCollapse?'el-icon-s-unfold':'el-icon-s-fold'" class="pointer" @click="isCollapse=!isCollapse"></i>
    </div>

    <div class="content">
      <template v-for="item in menuList">
        <div :key="item.name" v-if="item.divided" class="divided">
          <span>{{item.divided}}</span>
          <el-tooltip :disabled="!isCollapse" :content="item.divided" placement="right" effect="light">
            <i></i>
          </el-tooltip>
        </div>

        <div v-else-if="!item.divided&&item.authority.includes(user.role)" :key="item.name" class="menuItem" :class="item.name=== defaultActive?'active not-allowed':'pointer'" @click="$router.push({name: item.name})">
          <el-tooltip :disabled="!isCollapse" :content="item.title" placement="right" effect="light">
            <i :class="item.name==defaultActive?item.activeIcon:item.icon"></i>
          </el-tooltip>
          <span class="text">{{item.title}}</span>
        </div>
      </template>
    </div>
  </div>
</template>
 <script>
import { mapState } from "vuex";
const all = [
  'PROMOTER',  //会员
  'ADMIN'  //管理员
]
export default {
  components: {},
  data () {
    return {
      menuList: [
        {
          title: '课程中心',
          name: 'course',
          icon: 'el-icon-help',
          activeIcon: 'el-icon-s-help',
          authority: all
        },
        {
          divided: '业务管理',
          name: 'yhgl'
        },
        {
          title: '联盟会员',
          name: 'account',
          icon: 'el-icon-help',
          activeIcon: 'el-icon-s-help',
          authority: all
        },
        {
          title: '成单记录',
          name: 'deal',
          icon: 'el-icon-help',
          activeIcon: 'el-icon-s-help',
          authority: all
        },
        {
          title: '佣金明细',
          name: 'commission',
          icon: 'el-icon-help',
          activeIcon: 'el-icon-s-help',
          authority: all
        },
        {
          divided: '账号管理',
          name: 'zhgl'
        },
        {
          title: '个人中心',
          name: 'personal',
          icon: 'el-icon-user',
          activeIcon: 'el-icon-user-solid',
          authority: all
        }
      ],
      defaultActive: 'course',
      isCollapse: false,
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  watch: {
    "$route.meta": {
      handler ({ menu }) {
        this.defaultActive = menu
      },
      immediate: true
    }
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.menu {
  background: #131d35;
  height: 100vh;
  color: #fff;
  width: 200px;

  .top {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .logo {
      background-image: url(~@/static/logo2.png);
      width: 100px;
      height: 38.2px;
      background-size: 100px 38.2px;
      background-repeat: no-repeat;
      margin-right: 36px;
      text-align: center;
      line-height: 38.2px;
      font-weight: 700;
      font-size: 25px;
    }
  }

  .content {
    font-size: 15px;
    line-height: 22px;

    height: calc(100vh - 100px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    .divided,
    .menuItem {
      height: 46px;
      padding: 0 30px;
      display: flex;
      align-items: center;
    }
    .divided {
      color: #424a5d;
      margin-top: 10px;
    }
    .menuItem {
      color: #8796a8;
      .text {
        margin-left: 10px;
      }
      &:hover {
        @extend .active;
      }
    }
    .active {
      background: #0f2d52;
      color: #fff;
    }
    .not-allowed {
      cursor: not-allowed;
    }
  }
}
.collapse {
  width: 70px;
  .top {
    .logo {
      display: none;
    }
  }
  .content {
    .divided,
    .menuItem {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .divided {
      span {
        display: none;
      }
      i::before {
        font-family: "iconfont";
        content: "\e60c";
      }
    }
    .menuItem {
      .text {
        display: none;
      }
    }
  }
}
</style>