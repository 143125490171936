/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-28 11:23:48
 * @Module: 成单表单
 */
  <template>
  <el-dialog :title="type=='add'?'添加成单':type=='revision'?'修改成单':''" :visible.sync="dialogVisible" width="50%" @closed="closed">
    <div>
      <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
        <el-row :gutter="30">
          <el-col :span="8">
            <el-form-item label="单号" prop="number" :rules="{ required: true, message: '单号不能为空'}">
              <el-input v-model="form.number" placeholder="请输入单号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="成单金额" prop="amount" :rules="{ required: true, message: '成单金额不能为空'}">
              <el-input v-model.number="form.amount" placeholder="请输入成单金额"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户名称" prop="customerName" :rules="{ required: true, message: '客户名称不能为空'}">
              <el-input v-model="form.customerName" placeholder="请输入客户名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目名称" prop="projectName" :rules="{ required: true, message: '项目名称不能为空'}">
              <projectName v-model="form.projectName" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="成单会员" prop="accountUuid" :rules="{ required: true, message: '成单会员不能为空'}">
              <inviter v-model="form.accountUuid" placeholder="请选择成单会员" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="委托事项" prop="entrustedMatter">
              <el-input v-model="form.entrustedMatter" placeholder="请输入委托事项"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="委托事宜说明" prop="matterDesc">
              <el-input v-model="form.matterDesc" placeholder="请输入委托事宜说明"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="承接律所" prop="lawFirm">
              <el-input v-model="form.lawFirm" placeholder="请输入承接律所"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收款单位" prop="payee">
              <el-input v-model="form.payee" placeholder="请输入收款单位"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="缴费日期" prop="payAt">
							<el-date-picker style="width:100%;" value-format="timestamp" v-model="form.payAt" type="date" placeholder="请选择缴费日期" />
              <!-- <el-input v-model="form.payAt" placeholder="请输入缴费日期"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="当前进度" prop="currentProgress">
              <el-input v-model="form.currentProgress" placeholder="请输入当前进度"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="项目介绍" prop="projectIntro">
              <el-input v-model="form.projectIntro" placeholder="请输入项目介绍" type="textarea" :autosize="{minRows: 4, maxRows: 12}"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="note">
              <el-input v-model="form.note" type="textarea" :autosize="{minRows: 4, maxRows: 12}" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
import inviter from "@/views/Account/components/form/inviter.vue";
import projectName from "@/views/Deal/components/form/projectName.vue";
export default {
  components: {
    inviter,
    projectName
  },
  data () {
    return {
      type: 'add',
      dialogVisible: false,
      form: {
        dealUuid: null,

        number: null,
        amount: null,
        customerName: null,
        projectName: null,
        projectIntro: null,
        note: null,
        accountUuid: null,

        entrustedMatter: null,
        matterDesc: null,
        lawFirm: null,
        payee: null,
        payAt: null,
        currentProgress: null,

      },
    };
  },
  mounted () { },
  methods: {
    show ({ type = "add", data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
        console.log(data)
      }
      this.type = type;
      this.dialogVisible = true;
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postDeal()
        } else {
          return false
        }
      })
    },
    closed () {
      this.form = {
        dealUuid: null,

        number: null,
        amount: null,
        customerName: null,
        projectName: null,
        projectIntro: null,
        note: null,
        accountUuid: null,

        entrustedMatter: null,
        matterDesc: null,
        lawFirm: null,
        payee: null,
        payAt: null,
        currentProgress: null,
      }
      this.$refs.form.resetFields()
    },
    // 添加
    async postDeal () {
      const api = {
        revision: {
          func: this.$api.putDeal,
          params: {
            ...this.form,
            dealUuid: this.form.uuid
          }
        },
        add: {
          func: this.$api.postDeal,
          params: {
            ...this.form,
          }
        }
      }
      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>