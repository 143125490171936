import Router from '@/router'
const state = {
	user: {
		uuid: '',
		name: '',
		phone: '',
		idNumber: '',
		address: '',
		nativePlace: '',
		contactName: '',
		contactPhone: '',
		contactRelation: '',
		level: null,
		proportion: '',
		introducer: {
			name: ''
		},
		createdAt: '',
		token: '',
		role: ''
	}
}

const mutations = {
	setUserInfo (state, payload) {
		state.user = {
			...state.user,
			...payload
		};
		// state.commit("userLog/saveUserLog", state.user)
	},
	logout (state) {
		state.user = {
			uuid: '',
			name: '',
			phone: '',
			idNumber: '',
			address: '',
			nativePlace: '',
			contactName: '',
			contactPhone: '',
			contactRelation: '',
			level: null,
			proportion: '',
			introducer: {
				name: ''
			},
			createdAt: '',
			token: '',
			role: ''
		}
		Router.push({
			path: '/login', query: { redirect: Router.path }
		})
		window.clearVuexAlong();
	}

}
const actions = {
	setUserInfo ({ commit, state, dispatch }, params) {
		commit('setUserInfo', params);
	}
}

export default {
	namespaced: true,
	state,
	actions,
	mutations
}