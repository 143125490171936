/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-05 19:35:34
 * @Module: 公共头部
 */
 <template>
  <div class="header">
    <div class="title" v-if="$route.meta.title">
      {{$route.meta.title}}
    </div>
    <div class="moving" @click="$router.back()" v-else>
      <i class="el-icon-arrow-left"></i>
      返回
    </div>
    <div class="user">
      <el-dropdown @command="handleCommand">
        <div class="name pointer">{{user.name}}</div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="out">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>

</template>
 <script>
import { mapState } from "vuex";
export default {
  components: {},
  data () {
    return {};
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  mounted () { },
  methods: {
    handleCommand (command) {
      if (command === 'out') {
        this.logout()
      }
    },
    async logout () {
      try {
        await this.$confirm('此操作将退出当前登录账号, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.$message({
          type: 'success',
          message: '退出登录成功!'
        });
        this.$store.commit("userInfo/logout");
      }
      catch (error) {
        this.$message({
          type: 'info',
          message: '已取消退出登录'
        });
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
.header {
  height: 70px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  width: 100%;
  .title {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #262626;
  }
  .user {
    margin-right: 20px;
  }
}
</style>