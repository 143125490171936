/* 
 * @Author: 曹俊杰 
 * @Date: 2023-02-15 16:45:14
 * @Module: 课程中心
 */
<template>
  <div class="course">
    <el-card shadow="never" :body-style="{position:'relative'}">
      <div class="content">
        <div class="item" v-for="item in list" :key="item.uuid" @click="()=>openVideo(item)">
          <div class="cover" :style="`background-image:url(${$oss+item.coverUrl})`"></div>
          <div class="item-t">{{item.name}}</div>
          <div class="item-b">
            <div class="item-b-l">{{item.updatedAt|timeFormat}}</div>
          </div>
        </div>
      </div>
      <el-empty v-if="list.length==0"></el-empty>
      <hdqPagination layout="prev, pager, next,sizes" ref="pagination" @change="paginationChange" />
    </el-card>
  </div>
</template>
<script>
import hdqPagination from "@/components/hdqPagination.vue"
export default {
  components: { hdqPagination },
  data () {
    return {
      list: []
    };
  },
  mounted () { },
  activated () {
    //开启了keepAlive:true后再次进入，以前的搜索条件和页数都不会变，无论什么情况都调用一下获取数据的接口，这样就能得到当前搜索条件和页数的最新数据
    if (this.$route.meta.ifDoFresh) {
      //重置ifDoFresh
      this.$route.meta.ifDoFresh = false;
      //获取列表数据方法第一参数为是否重置搜索条件和页数
      this.getCourseList();
    }
  },
  beforeRouteEnter (to, from, next) {
    if ((from.name !== "courseDeatil") || (from.name !== "instituteJusticeDetail")) {
      to.meta.ifDoFresh = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },
  methods: {
    openVideo (item) {
      this.$router.push({ path: `/course/${item.uuid}` })
    },
    async getCourseList (current = 1, size = 10) {
      const { status, data } = await this.$api.getCourseList({ current, size })
      if (status === 200) {
        const { records } = data;
        this.list = records;
        this.$refs.pagination.createPagination(data);
      }
    },
    paginationChange ({ current, size }) {
      this.getCourseList(current, size)
    },
  },
};
</script>
<style lang='scss' scoped>
.course {
  .content {
    display: flex;
    flex-wrap: wrap;
    .item {
      margin-bottom: 26px;
      width: calc((100% - (26px * 3)) / 4);
      margin-right: 26px;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      .cover {
        width: 100%;
        // height: 150px;
        padding-bottom: calc(100% / 271 * 153);
        border-radius: 4px;
        background-size: 100% auto;
        background-repeat: no-repeat;
      }
      &:nth-child(4n) {
        margin-right: 0px;
      }
      &-t {
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        margin-top: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis; /* for Opera */
      }
      &-b {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-top: 8px;
      }
    }
  }
}
</style>
