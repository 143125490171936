import Vue from 'vue'
import Vuex from 'vuex'
import userInfo from './userInfo'
import course from './course'
import createVuexAlong from 'vuex-along'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		collapse: false
	},
	mutations: {
		collapse (state, payload) {
			state.collapse = payload
			// state.commit("userLog/saveUserLog", state.user)
		},
	},
	actions: {
	},
	modules: {
		userInfo,
		course
	},
	// vuex 状态储存   文档-https://github.com/boenfu/vuex-along#readme
	plugins: [createVuexAlong({
		local: {
			list: ["userInfo", "course"],
		}
	})]
})
