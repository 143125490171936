/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-25 17:21:32
 * @Module: 联盟会员表单
 */
 <template>
  <el-dialog :title="type=='add'?'创建会员':type=='revision'?'修改会员':''" :visible.sync="dialogVisible" width="40%" @closed="closed">
    <div>
      <el-form size="mini" label-position="top" label-width="120px" :model="form" ref="form">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="名称" prop="name" :rules="{ required: true, message: '名称不能为空'}">
							<el-input v-model="form.name" placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="type==='add'">
            <el-form-item label="登录手机号" prop="phone" :rules="{ required: true, message: '登录手机号不能为空'}">
              <el-input v-model="form.phone" placeholder="请输入登录手机号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号" prop="idNumber">
              <el-input v-model="form.idNumber" placeholder="请输入身份证号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="现住址" prop="address">
              <el-input v-model="form.address" placeholder="请输入现住址"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="籍贯" prop="nativePlace">
              <el-input v-model="form.nativePlace" placeholder="请输入籍贯"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="紧急联系人姓名" prop="contactName">
              <el-input v-model="form.contactName" placeholder="请输入紧急联系人姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="紧急联系人电话" prop="contactPhone">
              <el-input v-model="form.contactPhone" placeholder="请输入紧急联系人电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="紧急联系人关系" prop="contactRelation">
              <el-input v-model="form.contactRelation" placeholder="请输入紧急联系人关系"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="业务分润比例" prop="proportion">
              <el-input-number v-model="form.proportion" :min="0" :max="100" label="请输入业务分润比例"></el-input-number>
              <!-- <el-input v-model="form.proportion" placeholder="请输入业务分润比例"></el-input> -->
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="当前等级" prop="level">
              <grade v-model="form.level" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="介绍人" prop="introducerUuid" :rules="{ required: true, message: '介绍人不能为空'}">
              <inviter v-model="form.introducerUuid" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色" prop="role">
              <el-select v-model="form.role" style="width:100%;" placeholder="请选择角色">
                <el-option v-for="item in [
							{
								label:'会员',
								value:'PROMOTER'
							},
							{
								label:'管理员',
								value:'ADMIN'
							},
							]" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>

      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
      <el-button type="primary" @click="submit" size="mini">确 定</el-button>
    </span>
  </el-dialog>
</template>
 <script>
import grade from "./form/grade.vue";
import inviter from "./form/inviter.vue";
// import sourcea from "./form/source.vue";
export default {
  components: {
    grade,
    inviter,
    // sourcea
  },
  data () {
    return {
      type: 'add',
      dialogVisible: false,
      form: {
        phone: '',
        accountUuid: null,

        name: '',
        idNumber: '',
        address: '',
        nativePlace: '',
        contactName: '',
        contactPhone: '',
        contactRelation: '',
        level: null,
        proportion: '',
        introducerUuid: '',
        role: '',
      },
    };
  },
  mounted () { },
  methods: {
    show ({ type = "add", data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone({
          ...data,
          introducerUuid:
            data.introducer ? data.introducer.uuid : null
        });
      }
      this.type = type;
      this.dialogVisible = true;
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postaccount()
        } else {
          return false
        }
      })
    },
    closed () {
      this.form = {
        phone: '',
        accountUuid: null,

        name: '',
        idNumber: '',
        address: '',
        nativePlace: '',
        contactName: '',
        contactPhone: '',
        contactRelation: '',
        level: null,
        proportion: '',
        introducerUuid: '',
        role: '',
      }
      this.$refs.form.resetFields()
    },
    // 添加
    async postaccount () {
      const api = {
        revision: {
          func: this.$api.putRevisionAccount,
          params: {
            ...this.form
          }
        },
        add: {
          func: this.$api.putAddAccount,
          params: {
            ...this.form,
          }
        }
      }
      const { status, data, info } = await api[this.type].func(api[this.type].params)
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.$emit('success', this.type)
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>