/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-25 17:53:19
 * @Module: 级别下拉菜单
 */
 <template>
  <el-select :value="value" placeholder="请选择级别" clearable @change="change" style="width:100%;">
    <el-option v-for="item in list" :key="item.value" :label="item.label" :value="item.value">
    </el-option>
    <!-- <el-option v-for="item in 9" :key="item" :label="item" :value="item">
    </el-option> -->
  </el-select>
</template>
 <script>
export default {
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  components: {},
  data () {
    return {
      list: [
        {
          label: 'S',
          value: 1
        }, {
          label: 'A',
          value: 2
        }, {
          label: 'B',
          value: 3
        }, {
          label: 'C',
          value: 4
        }, {
          label: 'L',
          value: 5
        }
      ]
    };
  },
  mounted () { },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>