/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-25 11:46:00
 * @Module: 筛选表单
 */
 <template>
  <el-form :inline="true" :model="searchForm" size="mini" style="margin-top:20px;">
    <el-form-item label="户名">
      <el-input v-model="searchForm.keyword" placeholder="搜索户名或编号" clearable></el-input>
    </el-form-item>
    <el-form-item label="类型">
      <type v-model="searchForm.type" />
    </el-form-item>
    <el-form-item label="会员" v-if="['ADMIN','SUPER'].includes(user.role)">
      <inviter v-model="searchForm.accountUuid" placeholder="请选择会员" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">查询</el-button>
    </el-form-item>
  </el-form>
</template>
 <script>
import type from "@/views/Commission/components/form/type.vue"
import inviter from "@/views/Account/components/form/inviter.vue";
import { mapState } from "vuex";
export default {
  components: {
    type,
    inviter
  },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        keyword: '',
        accountUuid: '',
        type: ''
      })
    }
  },
  data () {
    return {
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  mounted () { },
  methods: {
    onSubmit () {
      this.$emit('submit')
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>