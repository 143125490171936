/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-25 19:17:45
 * @Module: 介绍人
 */
 <template>
  <el-select style="width:100%;" clearable :value="value" @change="change" filterable :placeholder="placeholder" :loading="loading">
    <el-option v-for="item in options" :key="item.uuid" :label="item.name" :value="item.uuid">
    </el-option>
  </el-select>
</template>
 <script>
export default {
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择介绍人'
    }
  },
  data () {
    return {
      options: [],
      loading: false
    };
  },
  mounted () {
    this.getAccountList()
  },
  methods: {
    async getAccountList () {
      const { status, data } = await this.$api.getAccountCandidateList()
      if (status == 200) {
        this.options = data;
      }
    },
    change (value) {
      this.$emit('input', value)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>