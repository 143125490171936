import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import defaultLayouts from '@/layouts/default'
import Course from '@/views/Course.vue' //  课程中心
import Personal from '@/views/Personal.vue' //  个人中心
import Login from '@/views/Login.vue'  // 登录

import Commission from '@/views/Commission.vue' // 分佣列表
import Deal from '@/views/Deal.vue' // 成单记录
import Account from '@/views/Account.vue' // 联盟会员  Account

Vue.use(VueRouter)
// 重复跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		component: defaultLayouts,
		redirect: '/course',
		children: [
			{
				path: '/course',
				name: 'course',
				meta: {
					title: '课程中心',
					menu: 'course',
					keepAlive: true,
					ifDoFresh: false,
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Course
			},

			{
				path: '/course/:uuid',
				name: 'courseDeatil',
				meta: {
					menu: 'course',
					title: '课程详情'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Course/CourseDeatil')
			},
			{
				path: '/course/:uuid/:lessonUuid',
				name: 'videoDeatil',
				meta: {
					menu: 'course',
					title: '课程详情'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import('@/views/Course/CourseVideo')
			},
			{
				path: '/personal',
				name: 'personal',
				meta: {
					title: '个人中心',
					menu: 'personal'
				},
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: Personal
			},
			{
				path: '/account',
				name: 'account',
				component: Account,
				meta: {
					menu: 'account',
					title: '联盟会员',
					transparency: true,
				}
			},
			{
				path: '/deal',
				name: 'deal',
				component: Deal,
				meta: {
					menu: 'deal',
					title: '成单记录',
					transparency: true,
				}
			},
			{
				path: '/commission',
				name: 'commission',
				component: Commission,
				meta: {
					menu: 'commission',
					title: '佣金明细',
					transparency: true,
				}
			},
			{
				path: '/withdrawAppProcess',
				name: 'withdrawAppProcess',
				component: () => import('@/views/Commission/withdrawAppProcess'),
				meta: {
					menu: 'commission'
				}
			},
		]
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
router.beforeEach((to, from, next) => {
	const { token } = store.state.userInfo.user
	// if (to.matched.some(record => record.meta.requireAuth)) {  // 判断该路由是否需要登录权限
	if (token) {  // 判断当前的token是否存在
		next();
	} else if (to.name === 'login') {
		next();
	} else {
		next({
			path: '/login',
			query: { redirect: to.fullPath }  // 将跳转的路由path作为参数，登录成功后跳转到该路由
		})
	}

})
export default router
