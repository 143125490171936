/* 
 * @Author: 曹俊杰 
 * @Date: 2022-11-25 10:25:10
 * @Module: 联盟会员
 */
 <template>
  <div class="account">
    <div style="mine-height:68px;background:#fff;dispaly:flex; align-items: center;	padding: 0 16px;overflow:hidden;">
      <searchForm :searchForm.sync="listParams" @submit="getAccountList" />
    </div>
    <el-row :gutter="20" style="margin-top:20px;">
      <el-col :span="6">
        <div style="padding:16px;background:#fff;border-radius: 4px;padding-top:0px;">
          <div style="display: flex;	justify-content: space-between;	align-items: center;height:56px;">
            <div>会员关系</div>
          </div>
          <el-tree @node-click="nodeClick" default-expand-all node-key="uuid" ref="tree" :data="accountTreeList" highlight-current :props="defaultProps"></el-tree>
        </div>
      </el-col>
      <el-col :span="18">
        <div style="padding:16px;background:#fff;border-radius: 4px;padding-top:0px;">
          <div style="display: flex;	justify-content: space-between;	align-items: center;height:56px;">
            <div>会员列表</div>
            <span v-if="['ADMIN'].includes(user.role)">
              <el-button type="primary" size="small" @click="add">创建会员</el-button>
            </span>
          </div>
          <el-table size="mini" @sort-change="sortChange" :data="list" tooltip-effect="dark" style="width: 100%;margin-top:10px;">
            <el-table-column type="expand">
              <template slot-scope="prop">
                <div style="padding:0 60px;">
                  <el-descriptions :column="1" labelClassName="contentLabel" contentClassName="contentValue">
                    <el-descriptions-item label="身份证号">{{prop.row.idNumber||"暂无信息"}}</el-descriptions-item>
                    <el-descriptions-item label="现住址">{{prop.row.address||"暂无信息"}}</el-descriptions-item>
                    <el-descriptions-item label="籍贯">{{prop.row.nativePlace||"暂无信息"}}</el-descriptions-item>
                    <el-descriptions-item label="紧急联系人人姓名">{{prop.row.contactName||"暂无信息"}}</el-descriptions-item>
                    <el-descriptions-item label="紧急联系人电话">{{prop.row.contactPhone||"暂无信息"}}</el-descriptions-item>
                    <el-descriptions-item label="紧急联系人关系">{{prop.row.contactRelation||"暂无信息"}}</el-descriptions-item>
                  </el-descriptions>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="名称" show-overflow-tooltip prop="name" min-width="120">
              <template slot-scope="scope">
                <div>
                  <div>
                    <span class="pointer primary" @click="()=>setCurrentKey(scope.row)">{{ scope.row.name||"--" }}</span>
                    <!-- <span>{{ scope.row.name||"--" }}</span> -->
                    <el-tag effect="dark" type="warning" size="mini" style="margin-left:6px;" v-if="scope.row.role==='ADMIN'">管理</el-tag>
                  </div>

                </div>
              </template>
            </el-table-column>

            <el-table-column label="手机号" prop="phone" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.phone ||"--"}}</template>
            </el-table-column>
            <!-- <el-table-column label="身份证号" prop="idNumber" show-overflow-tooltip>
              <template slot-scope="scope">
                <div>{{ scope.row.idNumber ||"--"}}</div>
              </template>
            </el-table-column> -->
            <el-table-column label="级别" sortable="level" prop="level" show-overflow-tooltip min-width="50">
              <template slot-scope="scope">{{ scope.row.level |level}}</template>
            </el-table-column>

            <!-- <el-table-column label="紧急联系人" prop="contactName" show-overflow-tooltip width="130">
              <template slot-scope="scope">
                <div>
                  <div>
                    <span>{{scope.row.contactName||'暂无信息'}}</span>
                    <span v-if="scope.row.contactRelation">-{{scope.row.contactRelation}}</span>
                  </div>
                  <div>电话：{{scope.row.contactPhone||'暂无信息'}}</div>
                </div>
              </template>
            </el-table-column> -->

            <el-table-column label="业务分润比例" sortable="proportion" prop="proportion" show-overflow-tooltip width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.proportion">{{ scope.row.proportion}}%</span>
                <span v-else>暂无信息</span>
              </template>
            </el-table-column>

            <!-- <el-table-column label="籍贯" prop="source" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.nativePlace ||"--"}}</template>
            </el-table-column> -->

            <el-table-column label="介绍人" prop="introducer.name" show-overflow-tooltip v-if="['ADMIN','SUPER'].includes(user.role)">
              <template slot-scope="scope">{{ scope.row.introducer?scope.row.introducer.name:"暂无信息"}}</template>
            </el-table-column>
            <!-- <el-table-column label="招生转化" prop="zszh" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.zszh ||"--"}}</template>
            </el-table-column>
            <el-table-column label="成单记录" prop="dealQuantity" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.dealQuantity ||"--"}}</template>
            </el-table-column>
            <el-table-column label="介绍会员" prop="inviteeQuantity" show-overflow-tooltip v-if="['ADMIN','SUPER'].includes(user.role)">
              <template slot-scope="scope">{{ scope.row.inviteeQuantity ||"--"}}</template>
            </el-table-column> -->
            <el-table-column label="录入日期" sortable="createdAt" prop="createdAt" show-overflow-tooltip>
              <template slot-scope="scope"> <span v-if="scope.row.createdAt">{{
                scope.row.createdAt | timeFormat
              }}</span>
                <span v-else>--</span></template>
            </el-table-column>
            <!-- <el-table-column label="备注" prop="note" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.note ||"--"}}</template>
            </el-table-column> -->

            <el-table-column label="操作" width="110" :resizable="false" v-if="['ADMIN'].includes(user.role)">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="()=>revision(scope.row)">编辑</el-button>
                <!-- <el-button type="text" size="mini" @click="()=>deleteData(scope.row)">删除</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <hdqPagination ref="pagination" layout="prev, pager, next,sizes" @change="getAccountList" />
        </div>
      </el-col>
    </el-row>
    <accountForm ref="accountForm" @success="accountFormSuccess" />
  </div>
</template>
 <script>
//  角色
// PROMOTER-会员
// ADMIN-管理员
import searchForm from "@/views/Account/components/searchForm"
import accountForm from "@/views/Account/components/accountForm"
import hdqPagination from "@/components/hdqPagination.vue"
import { mapState } from "vuex";
export default {
  components: {
    searchForm,
    accountForm,
    hdqPagination
  },
  data () {
    return {
      list: [],
      accountTreeList: [],
      listParams: {
        keyword: '',
        introducerUuid: '',
        level: null,
        orderBy: '',
        order: ''
      },
      defaultProps: {
        children: 'introduced',
        label: 'name'
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.userInfo.user
    })
  },
  filters: {
    level (num) {
      if (num == 1) {
        return 'S'
      } else if (num == 2) {
        return 'A'
      } else if (num == 3) {
        return 'B'
      } else if (num == 4) {
        return 'C'
      } else if (num == 5) {
        return 'L'
      } else {
        return '暂无数据'
      }
    }
  },
  mounted () {
    this.getAccountList();
    this.getAccountTreeList();
  },
  methods: {
    accountFormSuccess () {
      this.getAccountList();
      this.getAccountTreeList();
    },
    // 排序
    sortChange ({ order, prop }) {
      if (order) {
        this.listParams.order = order;
        this.listParams.orderBy = prop
      } else {
        this.listParams.order = '';
        this.listParams.orderBy = ''
      }
      this.getAccountList()
    },
    add () {
      this.$refs.accountForm.show({ type: 'add' })
    },
    revision (data) {
      this.$refs.accountForm.show({
        type: 'revision', data: {
          ...data, introducerUuid: data.introducer ? data.introducer.uuid : null
        }
      })
    },
    nodeClick ({ name }) {
      this.listParams.keyword = name
    },
    async getAccountList () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getAccountList({ current, size, ...listParams })
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data)
      }
    },
    async getAccountTreeList () {
      const { status, data } = await this.$api.getAccountTreeList()
      if (status == 200) {
        console.log(data)
        this.accountTreeList = [data];
      }
    },
    setCurrentKey ({ uuid }) {
      this.$refs.tree.setCurrentKey(uuid)
    }
  },
};
 </script>
 <style lang='scss' scoped>
.account {
  &/deep/.contentLabel {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    /* identical to box height */

    /* 字体/999999 */

    color: #999999;
  }
  &/deep/.contentValue {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    /* identical to box height */

    /* 字体/262626 */

    color: #262626;
  }
}
</style>